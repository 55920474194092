import React from 'react'
import styled from 'styled-components'
import { media } from '../../styles/util'
import { useConsultation } from '../../hooks/useConsultation'
import {
  TextInputForm,
  RadioButtonForm,
  NumberInputForm,
  TransitionScreen,
  BreedForm,
  AgeForm,
  CheckboxForm,
  FinalTransitionScreen,
  ConsultationResults
} from './screen-types'

const mapQuestionType = {
  textInputFields: TextInputForm,
  radioButton: RadioButtonForm,
  numberInput: NumberInputForm,
  transition: TransitionScreen,
  ageDropdown: AgeForm,
  breedDropdown: BreedForm,
  checkBox: CheckboxForm,
  finalTransition: FinalTransitionScreen,
  consultationResults: ConsultationResults
}

const PaddingWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  ${media.mobile`
    padding: 0 12px;
  `}
  ${media.tablet`
    padding: 0 30px;
  `}
  ${media.desktop`
    padding: 0 50px;
  `}
`

const Container = styled.div`
  background-color: white;
  border-radius: 25px;
  height: 100%;
  width: 100%;
  min-height: 400px;
  display: grid;
  place-items: center;
  margin: 0 auto;
  ${media.mobile`
    padding: 40px 16px;
  `}
  ${media.tablet`
    padding: 40px;
  `}
  ${media.desktop`
    padding: 40px 70px;
    max-width: 1200px;
  `}
`

const MainContent = () => {
  const { currentScreen, answers, submit, next, submitConsultation } = useConsultation()
  const { type } = currentScreen
  const QuestionComponent = mapQuestionType[type]
  return (
    <PaddingWrapper>
      <Container>
        <QuestionComponent screen={currentScreen} submit={submit} answers={answers} next={next} submitConsultation={submitConsultation} />
      </Container>
    </PaddingWrapper>
  )
}

export default MainContent
