import React from 'react'
import BackgroundWrapper from './BackgroundWrapper'
import MainContent from './MainContent'

const ConsultationWrapper = () => {
  return (
    <BackgroundWrapper>
      <MainContent />
    </BackgroundWrapper>
  )
}

export default ConsultationWrapper
