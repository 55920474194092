import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Consultation from 'src/components/Consultation'
import SEO from 'src/components/shared/SEO'

const ConsultationPage: React.FC<PageProps> = ({ location }) => {
  return (
    <>
      <SEO title="Consultation" location={location} />
      <Layout location={location}>
        <Consultation />
      </Layout>
    </>
  )
}

export default ConsultationPage
